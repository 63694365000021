@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        background: $primary-color;
        color: $base-white;
        padding: 6rem 0;

        .content {
            ul {
                margin: 0;
                gap: 16px;
                padding: 0;
                list-style: none;
                width: 100%;
                @include flex;
                @include flex-wrap;

                li {
                    padding: 0;
                    width: 100%;

                }
            }
        }

        .wrapper {
            gap: 30px;
            @include flex;
            @include flex-wrap;
        }

        .all-items {
            gap: 16px;
            display: flex;
            flex-wrap: wrap;

            a {
                gap: 10px;
                display: inline-flex;
                text-decoration: underline;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                }

                @include align-items(center);

                &:after {
                    content: "";
                    background-image: url("/site-assets/svg/arrow.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 22px;
                    height: 22px;
                    min-width: 22px;
                    display: block;
                }
            }

            >div {
                width: 100%;
            }

        }

        @media (min-width: $breakpoint-lg) {
            .content {
                ul {

                    li {
                        width: calc((100%/2) - 8px);
                    }
                }
            }

            .h3 {
                &:after {
                    content: "";
                    width: 100%;
                    max-width: 400px;
                    height: 128px;
                    display: block;
                    margin-left: 6rem;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: bottom;
                    background-image: url('data:image/svg+xml,<svg width="401" height="104" viewBox="0 0 401 104" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M372.589 99.3906C370.12 93.0899 372.15 83.641 373.264 77.3492C374.57 69.9753 377.219 63.2891 380.861 56.8014C381.736 55.2438 382.693 53.6902 383.457 52.0736C384.057 50.8058 380.51 52.9373 379.121 53.1329C369.147 57.6884 366.998 57.0989 358.362 56.6899C347.011 56.1524 340.59 56.9671 330.381 53.8286" stroke="%23FFB81C" stroke-width="3" stroke-linecap="round" stroke-dasharray="8 8"/><path d="M8.35713 16.7621C31.9376 48.514 71.7063 67.3929 108.385 78.594C161.404 94.7849 217.253 100.435 272.515 96.3619C292.718 94.8728 317.013 90.2353 336.392 82.5776C352.064 76.3851 348.518 74.6828 361.91 67.4005" stroke="%23FFB81C" stroke-width="3" stroke-linecap="round" stroke-dasharray="6 6"/></svg>');

                }
            }

            .wrapper {
                @include flex-wrap(nowrap);

                >div {
                    &:nth-child(1) {
                        width: 40%;
                    }

                    &:nth-child(2) {
                        width: 60%;
                    }
                }
            }

            .all-items {

                >div {
                    width: calc((100%/2) - 8px);
                }
            }
        }
    }

    &:global.teaching-jobs {
        padding: 1.5rem 0 6rem 0;

        @media (min-width: $breakpoint-lg) {
            .all-items {
                font-size: 27px;
                line-height: 36.45px;
            }
        }
    }
}